<template>
  <div>
    <v-card flat>
      <v-toolbar flat>
        <!-- <v-toolbar-title> Customer Forecast </v-toolbar-title> -->
        <!-- <v-spacer></v-spacer> -->
        <v-autocomplete
          class="ml-2"
          :items="organisations.data.filter(x=>x['is'+type])"
          item-text="relatedOrganisation.name"
                    :loading="loadingOrganisations"
          autofocus
          :label="type"
          item-value="relatedOrganisation.id"
          style="max-width: 400px; min-width: 300px"
          hide-details
          outlined
          return-object
          v-model="selectedCustomer"
          @change="getForecast()"
          clearable
          dense
        >
          <template v-slot:selection="data">
            <v-row align="center">
              <v-avatar
                size="32"
                :color="
                  data.item && data.item.relatedOrganisation.logo
                    ? 'white'
                    : 'secondary'
                "
                class="mr-2"
                v-if="data.item"
              >
                <v-img
                  v-if="data.item.relatedOrganisation.logo"
                  contain
                  :src="data.item.relatedOrganisation.logo"
                ></v-img>
                <h3 v-else>
                  {{ data.item.relatedOrganisation.name.charAt(0) }}
                </h3>
              </v-avatar>
              <span v-if="data.item.relatedOrganisation">
                {{ data.item.relatedOrganisation.name }}
              </span>
            </v-row>
          </template>
          <template v-slot:item="data">
            <v-list-item-action>
              <v-avatar
                size="32"
                :color="
                  data.item.relatedOrganisation.logo ? 'white' : 'secondary'
                "
              >
                <v-img
                  contain
                  v-if="data.item.relatedOrganisation.logo"
                  :src="data.item.relatedOrganisation.logo"
                ></v-img>
                <h3 v-else>
                  {{ data.item.relatedOrganisation.name.charAt(0) }}
                </h3>
              </v-avatar>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                data.item.relatedOrganisation.name
              }}</v-list-item-title>
              <v-list-item-subtitle
                v-if="data.item.relatedOrganisation.alias"
                >{{ data.item.relatedOrganisation.alias }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <v-spacer></v-spacer>
        <h4>Season:</h4>
        <v-btn
          :loading="loadingSeasons"
          icon
          class="mx-1"
          color="primary"
          @click="addSeason()"
        >
          <v-icon>add_circle_outline</v-icon>
        </v-btn>
        <v-chip-group
          class="ml-2"
          :loading="loadingSeasons"
          mandatory
          active-class="teal--text"
        >
          <v-chip
            v-for="season in availableSeasons"
            :value="season"
            :key="season.id"
            @click="getForecast(season)"
            @contextmenu="clickSeason($event, season)"
            >{{ season.name }}</v-chip
          >
        </v-chip-group>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-card
              :loading="loadingForecast"
              style="
                border-radius: 20px;
                background-color: var(--v-toolbar-lighten1) !important;
              "
            >
              <v-toolbar flat dense>
                <v-toolbar-title style="color: grey"
                  >Weekly Forecast</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-btn color="primary" icon @click="addNewForecastItem()"
                  ><v-icon>add_circle_outline</v-icon></v-btn
                >
              </v-toolbar>
              <v-card-text class="mt-0 pt-0">
                <div v-if="displayData.length > 0" class="mt-0 pt-0">
                
                  <div
                    class="mt-0 pt-0"
                    :style="{ width: '100%' }"
                    v-if="displayData.length > 0"
                  >
                    <v-virtual-scroll
                      class="my-0 py-0"
                      :bench="0"
                      :items="forecastItems"
                      height="625"
                      :width="'100%'"
                      item-height="45"
                    >
                      <template v-slot:default="{ item }">
                        <v-list-item
                          v-if="item.type == 'subheader'"
                          style="border-top: 0.5px solid grey; height: 45px"
                        >
                          <v-list-item-content
                            style="
                              font-size: 15px;
                              font-weight: bold;
                              color: grey;
                            "
                          >
                            <v-row class="pl-3" align="center">
                              <v-chip small color="blue-grey" style="color: white">
                                <v-icon small left
                                >event</v-icon
                              >
                                {{ item.name }}
                              </v-chip>
                            </v-row>
                          </v-list-item-content>
                          <v-list-item-content
                          >
                          <v-container style="max-height: 35px">
                            <v-row>
                          <v-col cols="12" sm="4">
                            <v-chip small>{{ item.containerQuantity }}</v-chip>
                                </v-col>
                                <v-col cols="12" sm="4">
                            <v-chip small>{{ item.palletQuantity }}</v-chip>
                                </v-col>
                                <v-col cols="12" sm="4">
                            <v-chip small>{{ item.unitQuantity }}</v-chip>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-else
                          @contextmenu="clickForecast($event, item)"
                          @click="editItem(item)"
                          style="height: 45px"
                        >
                          <v-list-item-content>
                            <v-container style="max-height: 35px">
                              <v-row justify="center" align="center">
                                <v-col cols="12" sm="2">
                                  <v-list-item class="mx-0 px-0">
                                    <v-list-item-content>
                                      <v-list-item-title
                                        style="font-size: 12px"
                                      >
                                        <v-icon small left color="grey"
                                          >label</v-icon
                                        >
                                        {{ $Format.capitalizeFirstLetter(item.product.name) }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle
                                        style="font-size: 11px"
                                      >
                                        {{ item.orderCargoItems.length }} Items
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="2" class="text-center">
                                  <!-- <v-chip v-if="item.status" small color="green" outlined>
                                  {{ item.status }}
                                  </v-chip> -->
                                  <v-chip
                                    v-if="item.destinationCountry"
                                    x-small
                                    outlined
                                    style="border: none"
                                  >
                                    <v-avatar size="20" left>
                                      <v-img
                                        contain
                                        :src="`https://cdn.loglive.io/flags/4x3/${item.destinationCountry.iso2.toLowerCase()}.svg`"
                                      ></v-img>
                                    </v-avatar>
                                    {{ item.destinationCountry.name }}
                                  </v-chip>
                                  <v-chip class="mt-1"
                                    v-if="item.weekType == 'Pack Week'"
                                    x-small
                                    color="orange"
                                    >Pack Week</v-chip
                                  >
                                  <v-chip class="mt-1"
                                    v-else-if="item.weekType == 'ETD Week'"
                                    x-small
                                    color="blue"
                                  >
                                    Departure Week</v-chip
                                  >
                                  <v-chip class="mt-1"
                                    v-else-if="item.weekType == 'ETA Week'"
                                    x-small
                                    color="orange"
                                    >Arrival Week</v-chip
                                  >
                                </v-col>
                                <v-col cols="12" sm="2">
                                 <v-chip x-small v-if="item.booking">
                                  <v-icon small left color="green">attach_money</v-icon>
                                  {{ item.booking.orderNo }}
                                 </v-chip>
                                </v-col>
                                <v-col cols="12" sm="2">
                                  <v-list-item-title style="font-size: 14px">
                                    {{ item.containerQuantity }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle style="font-size: 12px">
                                    Containers
                                  </v-list-item-subtitle>
                                </v-col>
                                <v-col cols="12" sm="2">
                                  <v-list-item-title style="font-size: 14px">
                                    {{ item.palletQuantity }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle style="font-size: 12px">
                                    Pallets
                                  </v-list-item-subtitle>
                                </v-col>
                                <v-col cols="12" sm="2">
                                  <v-list-item-title style="font-size: 14px">
                                    {{ item.unitQuantity }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle style="font-size: 12px">
                                    {{ formatBaseUnit(item.baseUnit) }}(s)
                                  </v-list-item-subtitle>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-virtual-scroll>
                  </div>
                </div>
                <div
                  class="container"
                  v-if="!loadingForecast && displayData.length == 0"
                >
                  <h3 style="color: grey">No Results Found</h3>
                </div>
                <div
                  class="container"
                  v-else-if="loadingForecast && displayData.length == 0"
                >
                  <valhalla-loading height="500px" />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <DynamicSmoothLineChart
              :data="constructedData"
              :key="graphKey"
              :labels="weeks()"
              defaultChart="line"
              xAxisLabel="Weeks"
              :textColor="'grey'"
            />
            <div class="mt-2">
              <v-row class="px-2">
                <v-col cols="12" sm="6">
                  <v-card
                    flat
                    style="
                      border-radius: 20px;
                      background-color: var(--v-toolbar-lighten1) !important;
                    "
                  >
                    <v-card-title style="font-size: 16px" class="mb-0 pb-0">
                      <v-icon small class="mr-2" color="grey"
                        >location_on</v-icon
                      >
                      Top Destinations</v-card-title
                    >
                    <v-card-text class="my-0 py-0">
                      <v-list dense class="my-0 py-0">
                        <v-list-item v-if="topCountries.length == 0">
                          <v-list-item-content class="text-center">
                            <span style="font-size: 12px; color: grey"
                              >No country data</span
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-for="(country, index) in topCountries"
                          :key="index"
                        >
                          <v-list-item-action>
                            <v-avatar size="32">
                              <v-img
                                :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"
                                contain
                              ></v-img>
                            </v-avatar>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ country.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ country.units }} Units
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{ country.percentage }}%
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card
                    flat
                    style="
                      border-radius: 20px;
                      background-color: var(--v-toolbar-lighten1) !important;
                    "
                  >
                    <v-card-title style="font-size: 16px" class="mb-0 pb-0">
                      <v-icon small class="mr-2" color="grey"
                        >category</v-icon
                      >
                      Top Varieties</v-card-title
                    >
                    <v-card-text class="my-0 py-0">
                      <v-list dense class="my-0 py-0">
                        <v-list-item v-if="topVarieties.length == 0">
                          <v-list-item-content class="text-center">
                            <span style="font-size: 12px; color: grey"
                              >No product data</span
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-for="(variety, index) in topVarieties"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ variety.product }} - {{ variety.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ variety.units }} Units
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{ variety.percentage }}%
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="createForecastModal"
      width="95vw"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <ForecastModal
        
        :seasons="availableSeasons"
        :related="organisations.data"
        :key="forecastItemKey"
        :updateKey="forecastItemKey"
        :forecastItem="forecastItem"
        :type="type"
        @close="createForecastModal = false"
        @save="getForecast"
      />
    </v-dialog>

    <v-dialog
      v-model="seasonModal"
      width="600px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Add Season </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="!season.name || !season.start || !season.end"
            :loading="savingSeason"
            @click="saveSeason"
          >
            <v-icon color="secondary">save</v-icon>
          </v-btn>
          <v-btn text @click="seasonModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="my-2" align="center">
            <v-col cols="12" sm="6">
              <v-text-field
                label="Name*"
                v-model="season.name"
                autofocus
                outlined
                dense
                clearable
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                class="my-2"
                hide-details
                ref="productSelect"
                hide-no-data
                hide-selected
                label="Product*"
                dense
                outlined
                :items="filterProducts"
                clearable
                item-text="name"
                item-value="id"
                :menu-props="{ closeOnContentClick: true }"
                v-model="season.productId"
                :loading="loadingProduct"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.type }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      v-if="data.item.parentProduct"
                      style="font-size: 12px"
                    >
                      Parent: {{ data.item.parentProduct.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-list-item>
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" class="text-left">
                      <v-list-item-action>
                        <v-icon :color="season.start ? 'success' : 'secondary'"
                          >today</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="season.start">
                          {{ season.start }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          Start Date
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-date-picker
                    v-model="season.start"
                    :max="season.end"
                    no-title
                    scrollable
                  >
                  </v-date-picker>
                </v-menu>
              </v-list-item>
            </v-col>
            <v-col cols="12" sm="6">
              <v-list-item>
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" class="text-left">
                      <v-list-item-action>
                        <v-icon :color="season.end ? 'success' : 'secondary'"
                          >today</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="season.end">
                          {{ season.end }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle> End Date </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-date-picker
                    v-model="season.end"
                    :min="season.start"
                    no-title
                    scrollable
                  >
                  </v-date-picker>
                </v-menu>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-menu
      v-model="showForecastMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list dense>
        <v-list-item @click="convertToOrder()" v-if="!forecastItem.bookingId">
          <v-list-item-action class="mr-1">
            <v-icon color="success">currency_exchange</v-icon>
          </v-list-item-action>
          <v-list-item-content class="ml-0 pl-0">
            <v-list-item-title> Convert to Order</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="viewOrder(forecastItem.booking)" v-if="forecastItem.booking">
          <v-list-item-action class="mr-1">
            <v-icon color="success">launch</v-icon>
          </v-list-item-action>
          <v-list-item-content class="ml-0 pl-0">
            <v-list-item-title> View Order</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        <v-list-item @click="cloneForecastItem()">
          <v-list-item-action class="mr-1">
            <v-icon color="blue">content_copy</v-icon>
          </v-list-item-action>
          <v-list-item-content class="ml-0 pl-0">
            <v-list-item-title> Clone Forecast Item</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="forecastItem && forecastItem.id" @click="confirmForecastDelete = true">
          <v-list-item-action class="mr-1">
            <v-icon color="red">delete</v-icon>
          </v-list-item-action>
          <v-list-item-content class="ml-0 pl-0">
            <v-list-item-title> Delete Forecast Item </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list dense>
        <v-list-item @click="editSeason()">
          <v-list-item-action class="mr-1">
            <v-icon color="blue">edit</v-icon>
          </v-list-item-action>
          <v-list-item-content class="ml-0 pl-0">
            <v-list-item-title> Edit Season </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="shiftSeasonModal=true">
          <v-list-item-action class="mr-1">
            <v-icon color="teal">refresh</v-icon>
          </v-list-item-action>
          <v-list-item-content class="ml-0 pl-0">
            <v-list-item-title> Shift Season </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="season && season.id" @click="confirmDelete = true">
          <v-list-item-action class="mr-1">
            <v-icon color="red">delete</v-icon>
          </v-list-item-action>
          <v-list-item-content class="ml-0 pl-0">
            <v-list-item-title> Delete Season </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="confirmForecastDelete"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmForecastDelete = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 12px">
            Are you sure you would like delete this forecast item?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmForecastDelete = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              :loading="deletingForecast"
              @click="deleteForecast()"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmDelete"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDelete = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 12px">
            Are you sure you would like delete this season and all of its
            forecasts?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmDelete = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              :loading="deletingSeason"
              @click="deleteSeason()"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="shiftSeasonModal" width="500px" >
      <v-card v-if="season">
        <v-toolbar dense flat color="transparent">
          <v-toolbar-title> {{ season.name }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="shiftSeasonModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 12px">
           How many weeks would you like to adjust the season by: <br/>
           
          </p>
          <v-col cols="12" class="text-center my-0 py-0">
            <el-input-number
                        :value="weekAdjustment"
                        size="mini"
                        v-model="weekAdjustment"
                      ></el-input-number>
          </v-col>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="blue"
              :disabled="weekAdjustment == 0"
              class="mx-2"
              text
              small
              :loading="shiftingSeason"
              @click="shiftSeason()"
              >Process Shift</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="orderModal" persistent width="95vw">
    <CreateOrder :order="order" :organisations="organisations" :loadingRelated="loadingOrganisations" @close="orderModal=false,order={}" @refresh="getForecast()"/>
    </v-dialog>
  </div>
</template>
<script>
import CreateOrder from "../Orders/CreateOrder.vue"
import DynamicSmoothLineChart from "../Charts/DynamicSmoothLineChart.vue";
import ForecastModal from "./ForecastModal.vue";
import * as moment from "moment";
export default {
  props: ['type', 'clearSelection'],
  components: {
    CreateOrder,
    DynamicSmoothLineChart,
    ForecastModal,
  },
  data: () => ({
    availableSeasons: [],
    confirmDelete: false,
    confirmForecastDelete: false,
    countries: [],
    createForecastModal: false,
    deletingForecast: false,
    deletingSeason: false,
    existingQuery: undefined,
    filteredResults: [],
    filters: {},
    forecastItem: {},
    forecastItemKey: 100,
    forecastItems: [],
    graphKey: 300,
    headers: [
      {
        text: "Week",
        value: "plannedWeekName",
      },
      {
        text: "Product",
        value: "product",
      },
      {
        text: "Destination",
        value: "destinationCountry",
      },
      {
        text: "Containers",
        value: "containerQuantity",
      },
      {
        text: "Pallets",
        value: "palletQuantity",
      },
      {
        text: "Units",
        value: "unityQuantity",
      },
    ],
    loadingForecast: false,
    loadingOrganisations: false,
    loadingProduct: false,
    loadingSeasons: false,
    modal: false,
    orderModal: false,
    order: {},
    organisations: {
      data: [],
    },
    products: {
      data: [],
    },
    productQuantityTypes: [
      { value: "BG", text: "Bag" },
      { value: "TT", text: "Bag, tote" },
      { value: "BA", text: "Barrel" },
      { value: "BL", text: "Bale" },
      { value: "BI", text: "Bin" },
      { value: "BX", text: "Box" },
      { value: "VL", text: "Bulk, liquid" },
      { value: "VR", text: "Bulk, solid" },
      { value: "BH", text: "Bunch" },
      { value: "BE", text: "Bundle" },
      { value: "CT", text: "Carton" },
      { value: "CS", text: "Case" },
      { value: "CN", text: "Container" },
      { value: "CR", text: "Crate" },
      { value: "DR", text: "Drum" },
      { value: "FB", text: "Flexibag" },
      { value: "JT", text: "Jutebag" },
      { value: "LU", text: "Lug" },
      { value: "PK", text: "Package" },
      { value: "PA", text: "Packet" },
      { value: "PX", text: "Pallet" },
      { value: "PT", text: "Pot" },
      { value: "PO", text: "Pouch" },
      { value: "RO", text: "Roll" },
      { value: "SH", text: "Sachet" },
      { value: "SA", text: "Sack" },
      { value: "SI", text: "Skid" },
      { value: "PU", text: "Tray" },
      { value: "VI", text: "Vial" },
    ],
    savingSeason: false,
    season: {},
    seasonModal: false,
    searchHeaders: {},
    searchKey: 500,
    selectedCustomer: null,
    selectedSeason: null,
    shiftSeasonModal: false,
    shiftingSeason: false,
    showMenu: false,
    showForecastMenu: false,
    sortBy: null,
    sortDesc: false,
    topCountries: [],
    topVarieties: [],
    x: null,
    y: null,
    weekAdjustment: 0,
  }),
  created() {
    this.getProducts();
    this.getSeasons();
    this.loadCountries()
    this.getRelations();
  },
  watch: {
    clearSelection(){
      this.selectedCustomer = null
      this.forecastItems = []
      this.filteredResults = []
      this.topCountries = []
      this.topVarieties = []
      this.graphKey ++
    },
  },
  computed: {
    calculatedHeight() {
      let height = window.innerHeight;
      return height * 0.63 + "px";
    },
    constructedData() {
      let result = this.forecastItems.filter((x) => x.type != "subheader");
      let setData = { containers: [], pallets: [], units: [] };
      let weeks = this.weeks(true);
      for (let i = 0; i < weeks.length; i++) {
        let week = weeks[i];
        let data = result.filter((x) => x.plannedWeekName == week.name);
        let containers =
          data.reduce((a, b) => a + b.containerQuantity, 0) ?? null;
        let pallets = data.reduce((a, b) => a + b.palletQuantity, 0) ?? null;
        let units = data.reduce((a, b) => a + b.unitQuantity, 0) ?? null;
        setData.containers.push(containers);
        setData.pallets.push(pallets);
        setData.units.push(units);
      }
      let data = [
        {
          data: setData.containers,
          type: "bar",
          color: "#3F51B5",
          symbol: "circle",
          smooth: true,
          showInLegend: true,
          name: "Containers",
        },
        {
          data: setData.pallets,
          type: "bar",
          color: "#009688",
          symbol: "circle",
          smooth: true,
          showInLegend: true,
          name: "Pallets",
        },
        {
          data: setData.units,
          type: "bar",
          color: "#2196F3",
          symbol: "circle",
          smooth: true,
          showInLegend: true,
          name: "Units",
        },
      ];
      return data;
    },
    displayData() {
      let result = this.filteredResults;
      if (this.search) {
        result = result.filter(
          (x) =>
            (x.orderNo &&
              x.orderNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.shipperName &&
              x.shipperName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.consigneeName &&
              x.consigneeName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.shippingLineName &&
              x.shippingLineName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.vesselVoyage &&
              x.vesselVoyage
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.regimeCode &&
              x.regimeCode.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.portOfLoadValue &&
              x.portOfLoadValue
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.portOfLoadCity &&
              x.portOfLoadCity
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.finalDestinationValue &&
              x.finalDestinationValue
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.finalDestinationCity &&
              x.finalDestinationCity
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.carrierReferenceNumber &&
              x.carrierReferenceNumber
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.shipperReferenceNo &&
              x.shipperReferenceNo
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.consigneeReferenceNo &&
              x.consigneeReferenceNo
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.customerName &&
              x.customerName.toLowerCase().includes(this.search.toLowerCase()))
        );
      }
      if (this.sortBy) {
        result = result.sort((a, b) => {
          if (this.sortDesc) {
            return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
          } else {
            return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
          }
        });
      }
      return result;
    },
    filterProducts() {
      let result = this.products.data.filter(
        (x) => x.type == "Product Group" || x.type == "Product"
      );
      return result;
    },
    tableWidth() {
      let result = 0;
      this.headers.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 150);
      });
      return result + "px";
    },
  },
  methods: {
    addSeason() {
      this.season = {
        year: new Date().getFullYear(),
        name: "",
        start: moment().startOf("year").format("YYYY-MM-DD"),
        end: moment().endOf("year").format("YYYY-MM-DD"),
      };
      this.seasonModal = true;
    },
    addNewForecastItem() {
      this.forecastItem = {
        
        seasonId: this.selectedSeason.id,
        season: this.selectedSeason,
        productId: this.selectedSeason.productId,
        week: moment().week(),
        plannedWeek: moment().week(),
        weekType: "Pack Week",
        currencyCode: "USD",
        containerised: true,
        palletised: true,
        containers: 0,
        pallets: 0,
        cartons: 0,
        palletPerContainer: 20,
        cartonPerPallet: 80,
        baseUnit: "CT",
        orderCargoItems: [],
      };
      if(this.type == 'Customer' && this.selectedCustomer){
        this.forecastItem.customerId = this.selectedCustomer.relatedOrganisation.id
        this.forecastItem.customer = this.selectedCustomer.relatedOrganisation
      } else if(this.selectedCustomer){
        this.forecastItem.supplierId = this.selectedCustomer.relatedOrganisation.id
        this.forecastItem.supplier = this.selectedCustomer.relatedOrganisation
      }
      this.forecastItemKey += 1;
      this.createForecastModal = true;
    },
    editItem(item) {
      this.forecastItem = item;
      let findSeason = this.availableSeasons.find(x=>x.id == item.seasonId)
      this.forecastItem.season = findSeason
      console.log(this.forecastItem)
      this.forecastItemKey += 1;
      this.createForecastModal = true;
    },
    calculateFilteredResults() {
      let filterKeys = Object.keys(this.filters);
      let filterResult = [];
      filterKeys.forEach((x) => {
        filterResult = [
          ...filterResult,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = filterResult;
      let result = [...this.forecastItems];
      let keys = Object.keys(this.filters);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.filters[key] && this.filters[key].length > 0) {
          result = result.filter((x) => this.filters[key].includes(x[key]));
        }
      }
      this.filteredResults = result;
    },
    async convertToOrder(){
      console.log(this.forecastItem)
      this.order = {
        recordType: 'ORDER',
        orderStatus: 'Planned',
        status: "Draft",
        transportType: "Container",
        movementType: "EXPORT",
        type: "LOGISTICS",
        modeOfTransport: "OCEAN",
        serviceType: "FCL",
        bookingContainerItems: [],
        plannedYear: this.forecastItem.plannedYear,
        plannedWeek: this.forecastItem.plannedWeek,
        originCountry: this.forecastItem.originCountry,
        originCountryId: this.forecastItem.originCountryId,
        destinationCountry: this.forecastItem.destinationCountry,
        destinationCountryId: this.forecastItem.destinationCountryId,
        orderCustomer: this.selectedCustomer.relatedOrganisation,
        orderCustomerId: this.selectedCustomer.relatedOrganisation.id,
        consigneeProfile: this.forecastItem.consigneeProfile,
        consigneeProfileId: this.forecastItem.consigneeProfileId,
        forecast: this.forecastItem,
        forecastId: this.forecastItem.id,
      }
      // if(this.order.consigneeProfileId){
      //   let profileDetail = await this.$API.getConsigneeProfile(this.order.consigneeProfileId);
      // this.order.consigneeProfile = profileDetail;
      // }
      if (
        !this.order.originCountry &&
        this.$store.state.currentOrg.countryCode
      ) {
        let find = this.countries.find(
          (x) => x.iso2 == this.$store.state.currentOrg.countryCode
        );
        if (find) {
          this.order.originCountry = find;
          this.order.originCountryId = find.id;
        }
      }
      let containerItems = [...new Set(this.forecastItem.orderCargoItems.map(x=>x.containerIndex))]
      for(let i=0;i<containerItems.length;i++){
        let containerCount = Math.ceil(this.forecastItem.orderCargoItems.filter(x=>x.containerIndex == containerItems[i]).map(x=>parseInt(x.quantity)).reduce((a,b)=>a+b,0)/this.forecastItem.unitPerContainer)
        
        let container = {
          quantity: containerCount,
          tempId: containerItems[i] + new Date().getTime(),
          index: containerItems[i],
          containerType: "45R1",
          containerTypeCode: "45R1",
          containerised: this.forecastItem.containerised,
          palletised: this.forecastItem.palletised,
          palletPerContainer: this.forecastItem.palletPerContainer,
          unitPerPallet: this.forecastItem.unitPerPallet,
          orderCargoItems: this.forecastItem.orderCargoItems.filter(x=>x.containerIndex == containerItems[i])
        }
        container.orderCargoItems = container.orderCargoItems.map((x)=>({
          ...x,
          quantity: Math.ceil(parseInt(x.quantity)/containerCount),
          product: this.forecastItem.product,
          productId: this.forecastItem.productId,
        }))
        container.orderCargoItems.forEach((x)=>{
          delete x.id
          delete x.createdAt
          delete x.updatedAt
        })
        // let items = this.forecastItem.orderCargoItems.filter(x=>x.containerIndex == container.containerIndex)
        this.order.bookingContainerItems.push(container)
      }
      console.log(this.order)
      this.orderModal = true
    },
    clickForecast(e, item) {
      e.preventDefault();
      this.showForecastMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.forecastItem = item;
      this.$nextTick(() => {
        this.showForecastMenu = true;
      });
    },
    clickSeason(e, item) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.season = item;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    cloneForecastItem(){
      let item = {...this.forecastItem}
      delete item.id
      item.orderCargoItems.forEach(x=>{
        delete x.id
      })
      item.season = this.selectedSeason
      this.forecastItem = item
      this.forecastItemKey += 1;
      this.createForecastModal = true;
    },
    async deleteForecast(){
      this.deletingForecast = true;
      await this.$API.updateForecast({
        id: this.forecastItem.id,
        isActive: false,
        isDeleted: true,
      });
     this.getForecast()
      this.deletingForecast = false;
      this.confirmForecastDelete = false;
      this.forecastItem = {};
    },
    async deleteSeason() {
      this.deletingSeason = true;
      await this.$API.updateSeason({
        id: this.season.id,
        isActive: false,
        isDeleted: true,
      });
      this.availableSeasons = this.availableSeasons.filter(
        (x) => x.id != this.season.id
      );
      this.deletingSeason = false;
      this.confirmDelete = false;
      this.season = {};
    },
    editSeason() {
      this.seasonModal = true;
    },
    formatBaseUnit(type) {
      let result = this.productQuantityTypes.find((x) => x.value == type);
      return result ? result.text : "";
    },
    async getForecast(season = null) {
      if (season) {
        this.selectedSeason = season;
      }
      if (this.selectedSeason && this.selectedCustomer) {
        if (this.loadingForecast && this.existingQuery) {
        this.existingQuery.abort()
      }
        this.loadingForecast = true;
        this.existingQuery = new AbortController();
        const signal = this.existingQuery.signal
        let params = {
            seasonId: this.selectedSeason.id,
            customerId: this.selectedCustomer.relatedOrganisation.id,
            forecastType: this.type.toUpperCase(),
          }
          if(this.type == 'Supplier'){
            params = {
              seasonId: this.selectedSeason.id,
              supplierId: this.selectedCustomer.relatedOrganisation.id,
              forecastType: this.type.toUpperCase(),
            }
          }
        this.forecastItems = await this.$API.getForecast({
          params,
          signal
        });
        this.graphKey++;
        this.loadingForecast = false;
        let uniqueCountries = [
          ...new Set(
            this.forecastItems
              .filter((x) => x.destinationCountryId)
              .map((x) => x.destinationCountryId)
          ),
        ];
        let countries = [];
        let totalUnits = this.forecastItems.filter(x=>x.id && x.unitQuantity).reduce(
          (a, b) => a + b.unitQuantity,
          0
        );
        for (let i = 0; i < uniqueCountries.length; i++) {
          let country = uniqueCountries[i];
          let countryData = this.forecastItems.filter(
            (x) => x.destinationCountryId == country
          );
          let countryName = countryData[0].destinationCountry.name;
          let containers = countryData.reduce(
            (a, b) => a + b.containerQuantity,
            0
          );
          let pallets = countryData.reduce((a, b) => a + b.palletQuantity, 0);
          let units = countryData.reduce((a, b) => a + b.unitQuantity, 0);
          countries.push({
            iso2: countryData[0].destinationCountry.iso2,
            name: countryName,
            containers: containers,
            pallets: pallets,
            units: units,
            percentage: Math.round((units / totalUnits) * 100),
          });
        }
        this.topCountries = countries
          .sort((a, b) => (a.units > b.units ? -1 : b.units > a.units ? 1 : 0))
          .slice(0, 5);

          let allVariants = [].concat.apply([], this.forecastItems.filter(x => x.orderCargoItems).map(x=> x.orderCargoItems))
          let uniqueVariants = [...new Set(allVariants.map(x => x.varietyProductId))].filter(Boolean)
          let varieties = []
          for(let i = 0; i < uniqueVariants.length; i++){
            let variant = uniqueVariants[i]
            let variantData = allVariants.filter(x => x.varietyProductId == variant)
            let variantName = variantData[0].varietyProduct.name
            let product = this.forecastItems.find(x=>x.id == variantData[0].forecastId)
            let units = variantData.reduce((a,b) => a + b.quantity, 0)
            varieties.push({
              product: product.product.name,
              name: variantName,
              units: units,
              percentage: Math.round((units / totalUnits) * 100),
            })
          }
          this.topVarieties = varieties.sort((a,b) => (a.units > b.units) ? -1 : (b.units > a.units) ? 1 : 0).slice(0,5)
        this.calculateFilteredResults();
      }
    },
    async getProducts() {
      this.loadingProducts = true;
      this.products = await this.$API.getProducts({});
      this.loadingProducts = false;
    },
    async getRelations() {
      this.loadingOrganisations = true;
      if (
        localStorage["relatedOrganisations_" + this.$store.state.currentOrg.id]
      ) {
        this.organisations = JSON.parse(
          localStorage[
            "relatedOrganisations_" + this.$store.state.currentOrg.id
          ]
        );
      }
      this.organisations = await this.$API.getRelationBasic({
      });
      localStorage.setItem(
        "relatedOrganisations_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.organisations)
      );
      this.loadingOrganisations = false;
    },
    async getSeasons() {
      this.loadingSeasons = true;
      this.availableSeasons = await this.$API.getSeasons();
      if (this.availableSeasons.length > 0 && !this.selectedSeason) {
        this.selectedSeason = this.availableSeasons[0];
      }
      this.loadingSeasons = false;
    },
    async loadCountries() {
      this.countries = await this.$API.searchCountriesBasic({});
     
    },
    headerOptions(value) {
      let result = [
        ...new Set(
          this.forecastItems
            .map((x) => ({ name: x[value] ?? "(Blank)", value: x[value] }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      if (this.searchHeaders[value]) {
        uniqueValues = uniqueValues.filter(
          (x) =>
            x &&
            x.toLowerCase().includes(this.searchHeaders[value].toLowerCase())
        );
      }
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      return result;
    },
    async saveSeason() {
      this.savingSeason = true;
      this.season.startWeek = moment(this.season.start).week();
      this.season.endWeek = moment(this.season.end).week();
      this.season.startYear = moment(this.season.start).year();
      this.season.endYear = moment(this.season.end).year();
      let result = {};
      if (!this.season.id) {
        result = await this.$API.createSeason(this.season);
      } else {
        result = await this.$API.updateSeason(this.season);
      }
      if (this.season.productId) {
        let findProduct = this.products.data.find(
          (x) => x.id == this.season.productId
        );
        if (findProduct) {
          result.product = findProduct;
        }
      }
      let index = this.availableSeasons.findIndex((x) => x.id == result.id);
      if (index > -1) {
        this.availableSeasons.splice(index, 1, result);
      } else {
        this.availableSeasons.push(result);
      }
      this.savingSeason = false;
      this.seasonModal = false;
      this.season = {};
      this.selectedSeason = result;
    },
    async shiftSeason(){
      this.shiftingSeason = true
      let result = await this.$API.shiftSeason({
        seasonId: this.season.id,
        weekAdjustment: this.weekAdjustment
      })
      this.shiftingSeason = false
      this.shiftSeasonModal = false
      this.getSeasons()
      this.getForecast()
    },
    weeks(returnObject = false) {
      let result = [];
      if (this.selectedSeason) {
        let start = this.selectedSeason.start
          ? new Date(this.selectedSeason.start)
          : new Date();
        let end = this.selectedSeason.end
          ? new Date(this.selectedSeason.end)
          : new Date();
        let diff = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
        let weeks = Math.ceil(diff / 7);
        let currentWeek = moment(start).week();
        let currentYear = moment(start).year();

        for (let i = 0; i < weeks; i++) {
          result.push({
            name: `${currentYear} - W${currentWeek}`,
            plannedWeek: currentWeek,
            plannedYear: currentYear,
            shortYear: currentYear.toString().substr(-2),
            friendlyName: `${currentYear
              .toString()
              .substr(-2)} - W${currentWeek}`,
          });
          currentWeek++;
          if (currentWeek > 52) {
            currentWeek = 1;
            currentYear++;
          }
        }
      }
      return returnObject ? result : result.map((x) => x.friendlyName);
    },
    viewOrder(item){
      this.$router.push({
        path: "/order/" + item.systemReference,
      });
    }
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  height: 550px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>